import * as React from "react"
import Layout from "../components/layout2023"
import SeoNoIndex from "../components/seo-no-index-ablyft"
import Results from "../components/results/results"

const FullServiceTeam = () => (
  <Layout nav={"hidden"}>
    <SeoNoIndex title="Results" index={false} follow={false} />
    <Results wrapperClass={"results"} />
  </Layout>
)

export default FullServiceTeam
