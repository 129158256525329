import React, { lazy, Suspense } from "react"
import { Link } from "gatsby"
import SiteNavigation from "../nav-new/nav"
import Image from "../image-component/image-2023"
import FallbackLoading from "../loader/loader";
import ReactCompareImage from 'react-compare-image';
import "./results.scss"
const SliderSection = lazy(() => import('./slider'))

const middleSliderContent = [
    {
        slideImage: 'brian-stetter.png',
        personName: 'Brian Stetter',
        personPosition: 'VP Digital Marketing',
        smallFeedback: 'In about 3 months, we improved our landing page conversion rates <b>by over 30%</b> ... an amazing business impact.',
        companyLogo: "deal-image-slider-logo.png",
        videoID: "m4mn1DZIJBM",
        videoLength: "3 min",
        logoAlt: "Ideal Image",
        url: "/testimonials#idealimage"
    },
    {
        slideImage: "megan-gouveia.png",
        smallFeedback:
            "We saw a <b>125% increase</b> in conversion rate for demo requests. And we have a big backlog of opportunities.",
        personName: "Megan Gouveia",
        personPosition:
            "Sr. Manager Digital Marketing",
        companyLogo: "progress-slider-logo.png",
        videoID: "oETkNoINKCQ",
        videoLength: "3 min",
        logoAlt: "Progress",
        url: "/testimonials#progress"
    },
    {
        slideImage: "amy-rose-carle.png",
        smallFeedback:
            "Spiralyze has had a really big impact. Our conversion rate was flat and now there is a <b>steady upward increase.</b>",
        personName: "Amy Rose Carle",
        personPosition: "Director of Operations",
        companyLogo: "nurture-life-slider-logo.png",
        videoID: "_JuuAIc8jyc",
        videoLength: "2 min",
        logoAlt: "Nurture life",
        url: "/testimonials#nurturelife"
    },
]
const bottomSliderContent = [
    {
        slideImage: "rodolfo-yiu.png",
        smallFeedback:
            "Through a number of page redesign tests we\'ve seen <b>test lifts between 40-90%.</b> They created a culture of testing and speed.",
        personName: "Rodolfo Yiu",
        personPosition: "Senior Manager, Digital Marketing",
        companyLogo: "okta-slider-logo.png",
        videoID: "xv3XXP_Tosg",
        videoLength: "6 min",
        logoAlt: "Okta",
        url: "/testimonials#okta"
    },
    {
        slideImage: "matt-boyce.png",
        smallFeedback:
            "The tests we've run have <b>improved conversion rates by 20-50%</b> and we have tests mapped out for the rest of the year.",
        personName: "Matt Boyce",
        personPosition: "Sr. Director of Demand Generation",
        companyLogo: "podium-slider-logo.png",
        videoID: "G-55Zm8r57s",
        videoLength: "4 min",
        logoAlt: "Podium",
        url: "/testimonials#podium"
    },
    {
        slideImage: "brian-mullins.png",
        smallFeedback:
            "We actually <b>increased our website conversion rate by 50%</b>, and increased the conversion quality.",
        personName: "Brian Mullins",
        personPosition: "VP, Digital and Product Marketing",
        companyLogo: "digital-guardian-slider-logo.png",
        videoID: "cP3CWxHmGSM",
        videoLength: "3 min",
        logoAlt: "Digital Guardian",
        url: "/testimonials#digitalguardian"
    }
]

export default function Results(props) {
    const isSSR = typeof window === "undefined"
    return (
        <span className={props.wrapperClass}>
            <section className={"hero-section"}>
                <SiteNavigation />
                <div className="hero-content-wrap">
                    <div className="hero-left">
                        <div className="hero-superheading">RESULTS</div>
                        <div className="hero-heading">Average 38% lift in the first 90 days. 68% in the first year.</div>
                        <div className="hero-subheading">
                            <p>Clients enjoy a dramatic lift in their first 90 days as we get quick wins from the most <span>proven test patterns.</span> Plus an ongoing stream of wins as we compensate for dropping win rate by increasing the volume of testing.</p>
                        </div>
                        <div className="page-anchors"><Link to="/results#first-days">First 90 Days</Link> · <Link to="/results#long-term-results">Long-Term Results</Link></div>
                        <Link className="arrow-button" to="/get-demo/">Get a Demo --></Link>
                    </div>
                    <div className="hero-right">
                        <ReactCompareImage
                            handle={<img src="https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/drag.svg" />}
                            handleSize={56}
                            sliderLineColor={'#2BA3F7'}
                            leftImage={'https://res.cloudinary.com/spiralyze/image/upload/f_auto/spiralyze2022/results/results-hero-control.png'}
                            rightImage={'https://res.cloudinary.com/spiralyze/image/upload/f_auto/spiralyze2022/results/results-hero-variant.png'}
                        />
                    </div>
                </div>
            </section>
            <section className="social-proof-section">
                <Image
                    sourceFolder={'homepage'}
                    lazyLoad={"true"}
                    alt={"Social proof"}
                    fallBackImage={'2023-social-logos-mobile.webp'}
                    imgName={[
                        {
                            imageName: "2023-social-logos-desktop.webp",
                            minBreakpoint: "992px",
                        },
                        {
                            imageName: "2023-social-logos-tablet.webp",
                            minBreakpoint: "768px",
                        },
                        {
                            imageName: "2023-social-logos-mobile.webp",
                            minBreakpoint: "320px",
                        },
                    ]}
                />
            </section>
            <section className="first-days result-section" id="first-days">
                <div className="result-super-heading">FIRST 90 DAYS</div>
                <div className="result-heading"><span>12 tests. 4 wins. 38% lift.</span><br /> Using proven winning tests.</div>
                <div className="result-subheading">Get dramatic results by using <span>proven test patterns.</span> Plus lay the foundation for ongoing learnings and wins with a full testing pipeline.</div>
                <div className="section-row">
                    <div className="row-left">
                        <Image
                            sourceFolder={'results'}
                            lazyLoad={"true"}
                            alt={"Average number of tests launched "}
                            className={"row-image"}
                            imgName={'start-fast.png'}
                        />
                    </div>
                    <div className="row-right">
                        <div className="row-content-wrap">
                            <div className="row-heading"><span>Start fast with</span> 12 tests in your first 90 days</div>
                            <div className="row-description">
                                <p>We start fast with the first test launching 2 weeks after the kickoff. The pace of testing accelerates as tests start flowing through the pipeline, with an average of 12 tests launched in the first 90 days of the engagement.</p>
                                <p>More high-quality tests leads to more wins and more results.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-row reverse">
                    <div className="row-left">
                        <Image
                            sourceFolder={'results'}
                            lazyLoad={"true"}
                            alt={"Win rate (in first 90 days)"}
                            className={"row-image"}
                            imgName={'win-rate.png'}
                        />
                    </div>
                    <div className="row-right">
                        <div className="row-content-wrap">
                            <div className="row-heading"><span>34% win rate by</span> running proven tests</div>
                            <div className="row-description">
                                <p>Enjoy an extraordinary 34% win rate, by running proven test from our prediction engine. Combined with running a high velocity of tests, you can expect 3 - 5 wins in your first three months.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-row distribution">
                    <div className="row-left">
                        <Image
                            sourceFolder={'results'}
                            lazyLoad={"true"}
                            alt={"Distribution of results"}
                            className={"row-image"}
                            imgName={'avg-lift.png'}
                        />
                    </div>
                    <div className="row-right has-grid-bg">
                        <div className="row-content-wrap">
                            <div className="row-heading"><span>38%</span> average Lift</div>
                            <div className="row-description">
                                <p>Clients enjoy an average 38% increase in their primary metric (typically MQLs) during their first 90 days.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Image
                sourceFolder={'results'}
                lazyLoad={"true"}
                alt={"Project Pipeline"}
                fallBackImage={'image-as-section.png'}
                className={"section-as-image"}
                imgName={[
                    {
                        imageName: "image-as-section.png",
                        minBreakpoint: "992px",
                    },
                    {
                        imageName: "image-as-section-tablet.png",
                        minBreakpoint: "768px",
                    },
                    {
                        imageName: "image-as-section-mobile.png",
                        minBreakpoint: "320px",
                    },
                ]}
            />
            {
                !isSSR && (
                    <Suspense fallback={<FallbackLoading />}>
                        <SliderSection slides={middleSliderContent}/>
                    </Suspense>
                )
            }
            <section className="first-days result-section" id="long-term-results">
                <div className="result-super-heading">ONGOING RESULTS</div>
                <div className="result-heading"><span>Create long-term growth</span><br /> by creating a pipeline and scaling learnings.</div>
                <div className="result-subheading">See how we sustain results over the long-term, using increased surface area and higher velocity to keep getting our clients consistent wins over the long-term.</div>
                <div className="section-row">
                    <div className="row-left">
                        <Image
                            sourceFolder={'results'}
                            lazyLoad={"true"}
                            alt={"Number of tests per month "}
                            className={"row-image"}
                            imgName={'more-tests.png'}
                        />
                    </div>
                    <div className="row-right">
                        <div className="row-content-wrap">
                            <div className="row-heading">Run more tests as we expand our testing surface area</div>
                            <div className="row-description">
                                <p>We run more tests by expand the testing surface area as your testing program matures. First, we layer on personalization based on industry, company size, new/returning status, and browsing behavior.  Second, we add pages including secondary pages, in-app, and resource registrations.  Third, we optimize beyond the form-fill focusing looking at schedule, show, and win rates.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-row reverse">
                    <div className="row-left">
                        <Image
                            sourceFolder={'results'}
                            lazyLoad={"true"}
                            alt={"Average win rate"}
                            className={"row-image"}
                            imgName={'win-rates.png'}
                        />
                    </div>
                    <div className="row-right">
                        <div className="row-content-wrap">
                            <div className="row-heading">Sustaining high win rates</div>
                            <div className="row-description">
                                <p>While win rates naturally drop as we eat the lowest-hanging fruit. We still sustain win rates 2-3X the industry average by running evidence-based experimentation.  With access to test latest tests that are working, plus our extensive client network - we are constantly finding new ways for you to keep pushing up conversion rates.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-full-width">
                    <div className="full-width-heading"><span>Long-term growth</span> in core metrics</div>
                    <div className="full-width-subheading">At the end of the 90 days clients also have a full pipeline of tests to sustain an ongoing testing program. Building a backlog of tests eliminate downtime between tests, run more tests, and get you faster results.</div>
                    <Image
                        sourceFolder={'results'}
                        lazyLoad={"true"}
                        alt={"Growth rate"}
                        className={"row-image"}
                        imgName={'growth.png'}
                    />
                </div>
            </section>
            {
                !isSSR && (
                    <Suspense fallback={<FallbackLoading />}>
                        <SliderSection slides={bottomSliderContent}/>
                    </Suspense>
                )
            }
        </span >
    )
}
